import { useSearchParams } from "@remix-run/react";
import { useQuery } from "@tanstack/react-query";

import { fetchDevices } from "./fetchers";

export const useActiveDevice = (): string | null => {
  const [searchParams] = useSearchParams();
  return searchParams.get("device_id");
};

export function useDeviceOptions(noTenantId?: boolean) {
  return useQuery({
    queryKey: ["devices"],
    queryFn: () => fetchDevices(false, noTenantId),
    select: (data) =>
      data.data.map<{ label: string; value: string }>((device) => ({
        label: device.name ?? "",
        value: device.id,
      })),
  });
}
