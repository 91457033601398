import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { DeviceSchema, PaginationMetaSchema } from "~/services/api-schemas";
import { http } from "~/services/http";

export async function fetchDevices(isUnassignedDevice?: boolean, noTenantId?: boolean) {
  const { data } = await http.get({
    path: "devices",
    params: { unassigned: String(isUnassignedDevice ?? false) },
    schema: z.object({
      data: z.array(DeviceSchema),
      meta: PaginationMetaSchema,
    }),
    noTenantId: isUnassignedDevice || noTenantId,
  });

  return data;
}

export async function deleteDevice(deviceId: string) {
  const { data } = await http.delete({
    path: `devices/${deviceId}`,
  });

  return data;
}

export function useUnknownDevices() {
  return useQuery({
    queryKey: ["unknownDevices"],
    queryFn: () => fetchDevices(true),
    select: (data) => data.data.filter((d) => d.tenantId === null),
  });
}
